import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";


import { CssBaseline, Typography } from "@material-ui/core";
import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";
import ContactFormSection from "./ContactFormSection";

const dashboardRoutes = [];

class ContactPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
          // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
          // brand="AL MIDIAFF"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <CssBaseline />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section} style={{ padding: "0 15%" }}>
            <div style={{ height: 160 }}></div>

            <div className={classes.container}>
              <GridContainer aria-label="breadcrumb">
                {/* <BrowserRouter>
                  <Link color="inherit" to="/">
                    Home /
                  </Link>
                </BrowserRouter>
                &nbsp;
                <BrowserRouter>
                  <Link color="inherit" to="#">
                    Contact
                  </Link>
                </BrowserRouter> */}

                <a href="/">Home /</a>
                &nbsp;
                <a href="#">Conatct</a>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={9} md={8} style={{}}>
                  <h1 className={classes.title} style={{ color: "#6C648B" }}>Contact Us</h1>
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ background: "#fff", maxWidth: "100%" }}>
              <div className={classes.section} style={{}}>
                
              </div>
              <ContactFormSection/>
            </div>
          </div>
          <div style={{ background: "#6C648B", width: "100%" }}>
            <div className={classes.container} >
              <NewsLetterSection />
            </div>
          </div>

          <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
          <FooterSection />


        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle, productStyle)(ContactPage);
